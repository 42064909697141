export default defineNuxtRouteMiddleware((to) => {
  const userStore = useUserStore()
  const { user, plan } = storeToRefs(userStore)

  // Only apply this middleware to team routes
  if (to.params.type !== 'team') return
  console.log('user', user.value, plan.value?.product?.name)
  // Check if user has necessary permissions
  const hasTeamAccess = user.value?.app_metadata?.subscription_status === 'active' && 
    !['Pro', 'Basic'].includes(plan.value?.product?.name)

  if (!hasTeamAccess) {
    // Redirect to user route while preserving team ID and query params
    return navigateTo({
      path: `/user/${to.params.team}`,
      query: to.query
    })
  }
})
